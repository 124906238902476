<div class="" *ngIf="!isApiInProgress">
    <div class="row mr-t" *ngIf="formShow">
        <div class="col-sm-12 mx-auto">
            <div class="card">
                <div class="card-body">
                    <label for="" [innerHtml]="showMess"></label>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid" *ngIf="formShow == false">
        <div class="row header mt-2">
            <div class="col-sm-8 header2">
                <span>28 SLBs FORM</span>
            </div>
            <div class="col-sm-4  text-end">
                <button type="button" class="btn btn-primary" style="margin-top: .2rem;" id="prevBtn" (click)="onPreview()">PREVIEW</button>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-body">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col" class="text-center">Sections/Indicators</th>
                                    <th scope="col" class="text-center">Actual Indicator 2021-22</th>
                                    <th scope="col" class="text-center">Target Indicator 2022-23</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let lineItem of formData | keyvalue : returnZero">

                                <tr>
                                    <th scope="row" colspan="3" class="th-h">{{lineItem?.key | titlecase }}</th>
                                </tr>
                                <tr *ngFor="let item of lineItem?.value">
                                    <th scope="row" class="l-n">{{item?.question}}</th>
                                    <td>
                                        <div class="input-group">
                                            <input [min]="item.min" [max]="item.max" [disabled]="item.actualDisable" [ngClass]="{'red': errorFieldLineItems.includes(item?.indicatorLineItem) || errorFieldLineItemsDec.includes(item.indicatorLineItem) || actual.invalid  && (actual.dirty || actual.touched)}"
                                                required id="actual" name="actual" type="text" class="form-control" placeholder="Range-{{item.range}}" [(ngModel)]="item.actual.value" #actual="ngModel" (blur)="validateDataInput($event, item)" (keydown)="numberLimitV($event, inputText1, item.min, item.max)"
                                                #inputText1>
                                            <span class="input-group-text" id="basic-addon2">{{item?.unit | uppercase}}</span>
                                            <div *ngIf="actual.invalid && (actual.dirty || actual.touched)">

                                                <span class="errorDis" *ngIf="actual.errors['required']">
                                       Actual is required.
                                  </span>
                                            </div>
                                            <div *ngIf="actual.dirty || actual.touched">
                                                <span class="errorDis" *ngIf="errorFieldLineItems.includes(item.indicatorLineItem)">
                                               Actual value must be less than the Target value.
                                            </span>
                                                <span class="errorDis" *ngIf="errorFieldLineItemsDec.includes(item.indicatorLineItem)">
                                               Actual value must be greater than the Target value.
                                            </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="input-group">
                                            <input [min]="item.min" [max]="item.max" [disabled]="item.targetDisable" [ngClass]="{'red': errorFieldLineItems.includes(item.indicatorLineItem) || errorFieldLineItemsDec.includes(item.indicatorLineItem) || target.invalid  && (target.dirty || target.touched)}"
                                                required id="target" name="target" type="text" class="form-control" placeholder="Range-{{item.range}}" [(ngModel)]="item.target_1.value" #target="ngModel" (blur)="validateDataInput($event, item)" (keydown)="numberLimitV($event, inputText2, item.min, item.max)"
                                                #inputText2>
                                            <span class="input-group-text" id="basic-addon2">{{item?.unit | uppercase}}</span>
                                            <div *ngIf="target.invalid && (target.dirty || target.touched)">

                                                <span class="errorDis" *ngIf="target.errors['required']">
                                            Target is required.
                                          </span>

                                            </div>
                                            <div *ngIf="target.dirty || target.touched">
                                                <span class="errorDis" *ngIf="errorFieldLineItems.includes(item.indicatorLineItem)">
                                                 Target value must be greater than the Actual value.
                                               </span>
                                                <span class="errorDis" *ngIf="errorFieldLineItemsDec.includes(item.indicatorLineItem)">
                                            Target value must be less than the Actual value.
                                          </span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="row mt-4 ms-0">
                            <div class="col-sm-12">
                                <label for="population"> Population* : </label>
                                <input [disabled]="isDisabled" class="form-control ms-3 d-inline-block w-auto" [ngClass]="{'red': population.invalid  && (population.dirty || population.touched)}" [(ngModel)]="slbData.population" required id="population" (keydown)="inputPopulation($event, population)"
                                    name="population" #population="ngModel" type="text" #population>
                                <div class="text-danger" *ngIf="population.invalid  && (population.dirty || population.touched) || popError">
                                    Population is required.
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3 mb-3">
          <div class="col-sm-12">
           <a routerLink="/ulbform/ulbform-overview" class="a-tag">Click Here</a> to view your performance against the 4 SLBs.
          </div>
        </div>

        <!-- action components -->
        <div class="row">
            <div class="col-sm-12">

                <app-common-action [actionRes]="actionRes" [formData]="slbFormData" [actionFor]="'ULBForm'" [actBtnDis]="actionBtnDis" (actionEventEmit)="actionData($event)" [canTakeAction]="canTakeAction" [commonActionError]="actionError" [formNamefiles]="'28slb'">
                </app-common-action>

            </div>
        </div>
        <div class="row mt-4 mb-3" *ngIf="isloadingComplte">
            <div class="col-sm-6">
                <button class="btn btn-c-back" routerLinkActive="router-link-active" [routerLink]="backRouter"><- Previous Form</button>
            </div>
            <div class="col-sm-6 text-end">
                <button (click)='save(true)' [disabled]="isDisabled" *ngIf="ulbData?.role == 'ULB'" class="btn btn-primary">Save As Draft</button>
                <button (click)='save(false)' [disabled]="isDisabled" *ngIf="ulbData?.role == 'ULB'" class="btn btn-primary ms-3">Submit</button>
                <span *ngIf="canTakeAction">
            <button class="btn btn-primary ms-3" *ngIf="ulbData?.role == 'STATE' || ulbData?.role == 'MoHUA'"
            [disabled]="actionBtnDis" (click)="saveAction()">Submit</button>
           </span>
                <button class="btn btn-c-next ms-3" routerLinkActive="router-link-active" [routerLink]="nextRouter">Next Form -></button>
            </div>
        </div>
    </div>
    <ng-template #templateSave>
        <div class="modal-body text-center ">
            <div class="modal-header">
                <h4 class="modal-title pull-left" style="color: red;"> <i>Unsaved Changes !</i> </h4>
                <div class="text-right">
                    <a (click)="alertClose()">
                        <img src="../../../../assets/form-icon/close-red-icon.png">
                    </a>
                </div>
            </div>

            <div class="row mb-3 mt-3">
                <div class="col-sm-12">{{alertError}}
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 text-end">
                    <button type="button" class="btn btn-light" (click)="discard()">Discard Changes</button>
                    <button type="button" class="btn btn-primary m-btn ms-2" (click)="stay()">Stay</button>
                    <button type="button" class="btn btn-success ms-2 m-btn" (click)="proceed()">
              Save & Proceed
            </button>
                </div>

            </div>

        </div>
    </ng-template>
</div>

<div class="text-center p-4" *ngIf="isApiInProgress">
    <app-pre-loader-new></app-pre-loader-new>
</div>
