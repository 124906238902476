<div class="card" *ngIf="loggedInUserType === userTypes.ULB && formDataChange?.status == 'PENDING' && formDataChange?.isDraft == false">
    <div class="card-body">
        <div class="row fw-bold">
            <div class="col-sm-3">Status:</div>
            <div class="col-sm-9">Under Review by State</div>

        </div>
    </div>
</div>
<div class="card" *ngIf="loggedInUserType === userTypes.STATE && !stateReview &&
formDataChange?.status == 'PENDING' && formDataChange?.isDraft == false">
    <div class="card-body">
        <div class="row fw-bold">
            <div class="col-sm-3">Status:</div>
            <div class="col-sm-9">Under Review by MoHUA</div>
        </div>
    </div>
</div>
<div class="action-cd" *ngIf="formDataChange?.status != 'PENDING' && formDataChange?.isDraft == false">
    <div class="card mb-3 mt-3 ">
        <div class="card-body ">
            <div class="row mb-3 fw-bold ">
                <div class="col-sm-3">Status:</div>
                <div class="col-sm-9">{{finalStatus}}</div>
            </div>
            <hr>
            <div class="stateRes" *ngIf="stateReview">
                <div class="row ">
                    <div class="col-sm-3">State Review Status:</div>
                    <!-- <div class="col-sm-9">{{formDataChange?.status}}</div> -->
                    <div class="col-sm-9">{{state_status}}</div>

                </div>
                <div class="row ">
                    <div class="col-sm-3">
                        {{formDataChange?.status == 'APPROVED' ? 'Remarks:' : 'Reason for return:'}}
                    </div>
                    <div class="col-sm-9">
                        {{formDataChange?.rejectReason_state}}
                    </div>
                </div>
                <div class="row ">
                    <div class="col-sm-3">
                        Document:
                    </div>
                    <div class="col-sm-9">
                        <a target="_blank" href="{{formDataChange?.responseFile_state?.url}} ">{{formDataChange?.responseFile_state?.name}}</a>
                    </div>
                </div>
            </div>
            <hr>
            <div class="mohuaRes " *ngIf="mohuaReview">
                <div class="row ">
                    <div class="col-sm-3">MoHUA Review Status:</div>
                    <!-- <div class="col-sm-9">{{mohuaStatus}}</div> -->
                    <div class="col-sm-9">{{mohua_status}}</div>
                </div>
                <div class="row ">
                    <div class="col-sm-3">
                        {{formDataChange?.status == 'APPROVED' ? 'Remarks:' : 'Reason for return:'}}
                    </div>
                    <div class="col-sm-9">
                        {{formDataChange?.rejectReason_mohua}}
                    </div>

                </div>
                <div class="row ">
                    <div class="col-sm-3">
                        Document:
                    </div>
                    <div class="col-sm-9">
                        <a target="_blank" href="{{formDataChange?.responseFile_mohua?.url}} ">{{formDataChange?.responseFile_mohua?.name}}</a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="card mt-2" *ngIf="loggedInUserType != userTypes.ULB && canTakeAction == true">
    <div class="card-body">
        <form *ngIf="statusForm" [formGroup]="statusForm">
            <div class="row">
                <div class="col-sm-5">
                    <h5 class="heading">Review Status :</h5>
                </div>
                <div class="col-sm-7">
                    <div class="form-group mb-2">
                        <div class="btn-group" btnRadioGroup formControlName="status">
                            <label for="" [matTooltip]="isPreviewYearApproved ? sequentialAlert : ''" matTooltipClass="tooltip-red"> 
                                <label btnRadio="APPROVED" [disabled]="isPreviewYearApproved" class="btn mb-0 customApprove" [ngClass]="{'customApproveActive' : activeClassApprove, 'dis-cls' : actBtnDis, 'app-seq' : isPreviewYearApproved}">Approve</label>
                            </label>
                            <label btnRadio="REJECTED" class="btn mb-0 customReturn" [ngClass]="{'customReturnActive' : activeClassReturn, 'dis-cls' : actBtnDis}">Return</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-sm-5 fontStyle" *ngIf="toggle?.status == 'APPROVED'">
                    Remark :
                </div>
                <div class="col-sm-5 fontStyle" *ngIf="toggle?.status == 'REJECTED'">
                    Reason for return<sup class="text-danger">*</sup> :
                </div>

                <div class="col-sm-7" *ngIf="toggle?.status == 'APPROVED' || toggle?.status == 'REJECTED'">
                    <textarea name="reason" id="reason" cols="33" rows="2" formControlName="reason" [disabled]="actBtnDis" placeholder="Type here ..." [ngClass]="{'dis-cls disGray' : actBtnDis}"></textarea>
                    <div *ngIf="item && toggle?.status == 'return'" class="text-danger">
                        {{item}}
                    </div>
                </div>
            </div>
            <div *ngIf="toggle?.status == 'APPROVED' || toggle?.status == 'REJECTED'">
                <div class="row mt-3">
                    <div class="col-sm-5 fontStyle">
                        Upload document :
                    </div>
                    <div class="col-sm-7 image-upload">
                        <label for="cin4">
                        <a class="btn btn-primary" [ngClass]="{'dis-cls' : actBtnDis}" (click)="uploadButtonClicked('A')">Upload
                            PDF
                            <img src="../../../../assets/form-icon/upload-pdf-icon.png" alt=""
                                style="margin-left: 4px;">
                        </a>
                        <p class="errorMessege" *ngIf="errorMessegeCommonAction">{{errorMessegeCommonAction}}</p>
                    </label>
                        <input id="cin4" name="cin4" type="file" [disabled]="actBtnDis" onclick="this.value = null;" (change)="fileChangeEvent($event, 'commonActProgress')" accept=".pdf" />
                        <!-- <div>
                        <div *ngIf="submitted && f?.stateNotification?.controls?.name?.errors" class="customDate text-danger">
                            <div *ngIf="f?.stateNotification?.controls?.name?.errors.required">File is required.</div>
                        </div>
                    </div>             -->
                    </div>
                </div>
                <div class="row mt-3" *ngIf="showCommonAct">
                    <div class="col-sm-5 filePadding">
                        {{commonActFileName}}
                    </div>
                    <div class="col-sm-2 progressBar" *ngIf="commonActProgress">
                        <div class="progress-bar" *ngIf="commonActProgress" role="progressbar" attr.aria-valuenow="{{ commonActProgress }}" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{ width: commonActProgress + '%' }">
                            {{ commonActProgress }}%
                        </div>
                    </div>
                    <div class="col-sm-4 filePadding">
                        <a href="{{ stateActUrl }}" target="_blank">
                            <img src="../../../../assets/form-icon/download-pdf-icon.png" alt="">
                        </a>
                        <span>
                        <a (click)="clearFile('stateAct')" style="margin-left: 4px;"
                        [ngClass]="{'dis-cls' : actBtnDis}">
                            <img src="../../../../assets/form-icon/close-red-icon.png" alt="">
                        </a>
                    </span>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="errorF">
                <div class="col-sm-12 text-danger">
                    Required*
                </div>
            </div>
        </form>
    </div>
</div>