<div class="container-fluid">
    <!-- *ngIf="isUserVerified" -->

    <div class="row mb-3">
        <div class="d-flex flex-column flex-shrink-0 p-3 col-sm-2 text-light" style="background-color: #114551;"
        [ngClass]="{'sidebar': userData.role == 'ADMIN' || userData.role == 'MoHUA' || userData.role === 'STATE'}">

            <a class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-light text-decoration-none"
                routerLink="/fc-home-page">
                <div class="row">
                    <div class="col-sm-2">
                        <mat-icon>home</mat-icon>
                    </div>
                    <div class="col-sm-10 collapsable">
                        15FC Grants Home
                    </div>
                </div>
            </a>

            <hr>
            <ul class="nav nav-pills flex-column mb-auto">
                <ng-container *ngFor="let menuItem of leftMenu | keyvalue">
                    <li class="out-f-h px-3 py-2 collapsable" *ngIf="menuItem?.key != ''">
                        <!-- <mat-icon class="pull-right" aria-hidden="false">
                            <img src="../../../assets/form-icon/checked.svg">
                        </mat-icon> -->
                        <span >
                            {{menuItem?.key}}
                        </span>
                    </li>
                    <li  *ngFor="let sideItem of menuItem?.value">
                        <a routerLinkActive="active" [routerLink]="sideItem?.url" class="nav-link link-light">
                            <mat-icon aria-hidden="false" class="svg-gt ml-2">
                                <img class="upscale-icon" src="{{sideItem?.icon}}">
                            </mat-icon>
                            <span class="collapsable" style="margin-left: 10px;">{{sideItem?.name}}</span>
                            <mat-icon class="pull-right collapsable" aria-hidden="false" [tooltip]="sideItem?.tooltip">
                                <img src="{{sideItem?.tick}}">
                            </mat-icon>
                        </a>
                    </li>
                </ng-container>
            </ul>
            <div class="row mt-3 mb-3 d-none">
                <div class="col-sm-12 text-center">
                    <button class="btn btn-primary">Preview & Download</button>
                </div>
            </div>
        </div>

        <div class="col-sm">
            <!-- <div class="row mt-1" *ngIf="loggedInUserType !== userTypes.ULB && path">
                {{path}}, {{pathMohua}}
                <div class="col-sm-12">
                    <a type="button" (click)="backStatePage()" class="breadLink">
                       &laquo;
                       <span *ngIf="loggedInUserType === userTypes.STATE && !pathMohua">
                        ULB Review
                       </span>
                       <span *ngIf="loggedInUserType === userTypes.MoHUA && pathMohua">
                        State Review
                       </span>
                      </a>
                    <a type="button" (click)="backStatePage2()" class="breadLink ms-2" *ngIf="pathMohua && loggedInUserType !== userTypes.STATE">
                      &laquo;
                       ULB Review
                    </a>

                </div>
            </div> -->
            <div class="row mt-1" *ngIf="loggedInUserType !== userTypes.ULB">
                <div class="col-sm-12 br-cd">

                    <span class="" *ngIf="loggedInUserType === userTypes.MoHUA || loggedInUserType === userTypes.ADMIN">

                        <a type="button" *ngIf="path" class="breadLink" (click)="backStatePage('ULB Review')">
                            ULB Review
                        </a>
                        <span *ngIf="pathMohua">
                            /
                        </span>
                    <a type="button" *ngIf="pathMohua" class="breadLink ms-2" (click)="backStatePage('State Review')">
                            State Review
                        </a>
                    </span>
                    <a type="button" (click)="backStatePage2()" class="breadLink" *ngIf="loggedInUserType === userTypes.STATE && path">
                        &laquo;
                        <!-- BACK(STATE) -->
                        ULB Review
                    </a>

                </div>
            </div>
            <div class="row">
                <div class="col header1" [ngClass]="{'mt-0': (loggedInUserType !== userTypes.ULB && path)}">
                    <h3 class="text-center" style="
                  text-align: center;
                  margin-bottom: 5px;
                  font-weight: 600;
                  font-size: 1.5rem;
                " *ngIf="loggedInUserType !== userTypes.USER">
                        <span *ngIf="loggedInUserType === userTypes.ULB">
                            {{ userLoggedInDetails.name }},
                        </span>
                        <span *ngIf="loggedInUserType === userTypes.ADMIN ||
                                      loggedInUserType === userTypes.MoHUA ||
                                      loggedInUserType === userTypes.PARTNER">
                            {{ userLoggedInDetails.role }}
                            <div>
                                <span style="font-size: 18px; font-weight: 600;">
                                    {{ulbName}}<span *ngIf="ulbName != ''">,</span> {{stateName}}
                        </span>
                </div>
                </span>
                <span *ngIf="states &&
                              (loggedInUserType === userTypes.STATE ||
                                loggedInUserType === userTypes.ULB)">
                            <span *ngIf="loggedInUserType !== userTypes.ULB">
                                {{ulbName}}<span *ngIf="ulbName != ''">,</span>
                </span> {{ states[userLoggedInDetails["state"]]?.name }}


                </span>
                </h3>

            </div>
        </div>
        <!-- <div class="row" *ngIf="loggedInUserType !== userTypes.ULB && path">
            <div class="col-sm-12">
                <button type="button" (click)="backStatePage()" class="btn btn-c-back br-e">
                       &laquo;
                       BACK
                       <span *ngIf="loggedInUserType === userTypes.STATE">
                        (STATE)
                       </span>
                       <span *ngIf="loggedInUserType === userTypes.MoHUA">
                        (MoHUA)
                       </span>
                </button>
                <button type="button" (click)="backStatePage2()" class="btn btn-c-back br-f" *ngIf="pathMohua && loggedInUserType !== userTypes.STATE">
                 &laquo;
                BACK(STATE)
            </button>

            </div>
        </div> -->
        <router-outlet></router-outlet>
    </div>
</div>

</div>
